import Row from '@pretto/bricks/website/about/components/Row'

import {
  HERO_BUTTON,
  HERO_INPUT,
  HERO_MEDIA,
  HERO_PICTURE,
  HERO_RATES,
  HERO_SIMULATOR,
  ROW_CONTENT,
  ROW_INPUT,
  ROW_SIMULATOR2,
} from '@pretto/website/src/config/rowsNames'
import { mapObject } from '@pretto/website/src/templates/blocs/lib/mapObject'
import { SimulatorRowContainer } from '@pretto/website/src/templates/blocs/rows/SimulatorRowContainer'
import { Blocs as BlocsView } from '@pretto/website/src/templates/blocs/views/Blocs/Blocs'

import loadable from '@loadable/component'
import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'

import { getNavbarBackgroundColor } from '../../lib/getNavbarBackgroundColor'

const SecondaryMenu = loadable(() => import('@pretto/website/src/templates/blocs/rows/secondaryMenu/SecondaryMenu'), {
  resolveComponent: components => components.SecondaryMenu,
})

const HeroButton = loadable(() => import('@pretto/website/src/templates/blocs/rows/heroButton/components/HeroButton'), {
  resolveComponent: components => components.HeroButton,
})

const HeroInput = loadable(() => import('@pretto/website/src/templates/blocs/rows/heroInput/HeroInputContainer'), {
  resolveComponent: components => components.HeroInputContainer,
})

const HeroSimulator = loadable(
  () => import('@pretto/website/src/templates/blocs/rows/heroSimulator/HeroSimulatorContainer'),
  {
    resolveComponent: components => components.HeroSimulatorContainer,
  }
)

const RowSimulator = loadable(
  () => import('@pretto/website/src/templates/blocs/rows/rowSimulator/RowSimulatorContainer'),
  {
    resolveComponent: components => components.RowSimulatorContainer,
  }
)

const HeroPicture = loadable(
  () => import('@pretto/website/src/templates/blocs/rows/heroPicture/components/HeroPicture/HeroPicture'),
  {
    resolveComponent: components => components.HeroPicture,
  }
)

const HeroRatesContainer = loadable(
  () => import('@pretto/website/src/templates/blocs/rows/heroRates/HeroRatesContainer'),
  {
    resolveComponent: components => components.HeroRatesContainer,
  }
)

const HeroMedia = loadable(() => import('@pretto/website/src/templates/blocs/rows/heroMedia/HeroMediaContainer'), {
  resolveComponent: components => components.HeroMediaContainer,
})

const RowSummary = loadable(() => import('@pretto/website/src/templates/blocs/rows/rowSummary/RowSummaryContainer'), {
  resolveComponent: components => components.RowSummaryContainer,
})

const RowCards = loadable(() => import('@pretto/website/src/templates/blocs/rows/rowCards/components/RowCards'), {
  resolveComponent: components => components.RowCards,
})

const RowInput = loadable(() => import('@pretto/website/src/templates/blocs/rows/rowInput/RowInputContainer'), {
  resolveComponent: components => components.RowInputContainer,
})

const RowContent = loadable(() => import('@pretto/website/src/templates/blocs/rows/rowContent/components/RowContent'), {
  resolveComponent: components => components.RowContent,
})

const RowCarousel = loadable(
  () => import('@pretto/website/src/templates/blocs/rows/rowCarousel/components/RowCarousel'),
  {
    resolveComponent: components => components.RowCarousel,
  }
)

const RowTextPicture = loadable(
  () => import('@pretto/website/src/templates/blocs/rows/rowTextPicture/components/RowTextPicture'),
  {
    resolveComponent: components => components.RowTextPicture,
  }
)

const RowTimeLine = loadable(
  () => import('@pretto/website/src/templates/blocs/rows/rowTimeLine/components/RowTimeLine'),
  {
    resolveComponent: components => components.RowTimeLine,
  }
)

const RowMedia = loadable(() => import('@pretto/website/src/templates/blocs/rows/rowMedia/RowMediaContainer'), {
  resolveComponent: components => components.RowMediaContainer,
})

const DistributionRow = loadable(() => import('@pretto/website/src/templates/blocs/rows/DistributionRow'), {
  resolveComponent: components => components.DistributionRow,
})

const RowRatesContainer = loadable(
  () => import('@pretto/website/src/templates/blocs/rows/rowRates/RowRatesContainer'),
  {
    resolveComponent: components => components.RowRatesContainer,
  }
)

const InputBannerRow = loadable(() => import('@pretto/website/src/templates/blocs/rows/InputBannerRow'), {
  resolveComponent: components => components.InputBannerRow,
})

const ProfilRow = loadable(() => import('@pretto/website/src/templates/blocs/rows/ProfilRow'), {
  resolveComponent: components => components.ProfilRow,
})

const RatesAnalysisRow = loadable(() => import('@pretto/website/src/templates/blocs/rows/RatesAnalysisRow'), {
  resolveComponent: components => components.RatesAnalysisRow,
})

const RegionsRow = loadable(() => import('@pretto/website/src/templates/blocs/rows/RegionsRow'), {
  resolveComponent: components => components.RegionsRow,
})

const VideoRow = loadable(() => import('@pretto/website/src/templates/blocs/components/videoRow/VideoRow'), {
  resolveComponent: components => components.VideoRow,
})

const components = {
  WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsArticleslink: 'BlocArticleLink',
  WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsHeroN1: 'HeroN1',
  WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsHeroN2: 'HeroN2',
  WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsInfo: 'BlocInfo',
  WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsLabels: 'BlocLabels',
  WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsLinks: 'BlocLink',
  WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsPictos: 'BlocPictos',
  WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsTabs: 'BlocTabs',
}

const blocks = Object.entries(components).reduce(
  (previous, [key, value]) => ({
    ...previous,
    [key]: loadable(() => import(`@pretto/bricks/website/about/components/${value}/index.js`)),
  }),
  {}
)

export const Blocs = ({
  articlesProps,
  data,
  isSecondaryMenuDisplayed,
  menu,
  pictoComponents,
  rates,
  ratesSettings,
  secondaryMenu,
  tagsData,
  ...props
}) => {
  const { pathname } = useLocation()

  const [isHeroWithPromises, setIsHeroWithPromises] = useState(false)

  const menuComponent = useMemo(() => {
    const menuItems = isSecondaryMenuDisplayed
      ? secondaryMenu.nodes[0].menuItems.nodes.map(({ label, uri }) => ({
          href: uri,
          id: label,
          label,
          isSelected: uri === pathname,
        }))
      : menu?.menuItems.nodes.map(({ label, url }) => ({
          href: url,
          id: label,
          label,
          isSelected: url === pathname,
        }))

    if (!menuItems || !menuItems.length) {
      return null
    }

    return <SecondaryMenu items={menuItems} color={getNavbarBackgroundColor(data.template)} />
  }, [])

  const heroComponent = useMemo(() => {
    if (data.template.about.hero?.[0]) {
      const { __typename, ...rowProps } = data.template.about.hero[0]

      switch (__typename) {
        case HERO_BUTTON: {
          if (rowProps.arePromisesDisplayed && rowProps.promises.length > 1) {
            setIsHeroWithPromises(true)
          }
          return <HeroButton title={data.title} {...rowProps} />
        }

        case HERO_INPUT:
          if (rowProps.arePromisesDisplayed && rowProps.promises.length > 1) {
            setIsHeroWithPromises(true)
          }
          return <HeroInput title={data.title} {...rowProps} />

        case HERO_MEDIA:
          return (
            <HeroMedia
              articlesProps={articlesProps}
              rowData={{ databaseId: data.databaseId, tagsData }}
              title={data.title}
              {...rowProps}
            />
          )

        case HERO_PICTURE: {
          const heroPictureContainerProps = {
            authorProps: {
              date: data.modified || data.date,
              name: data.author.node.name,
              isUpdated: !!data.modified,
              picture: data.author.node.userMeta.photoSlug,
              url: data.uri,
            },
            category: data.primaryCategory?.node.name,
            image: rowProps.picture,
            isColored: rowProps.isColored || false,
            title: data.title,
          }

          return <HeroPicture {...heroPictureContainerProps} />
        }

        case HERO_RATES:
          return <HeroRatesContainer isHero rates={rates.data} title={data.title} {...rowProps} />

        case HERO_SIMULATOR:
          return <HeroSimulator title={data.title} {...rowProps} />

        default:
          return null
      }
    }
  }, [data])

  const rowSummary = useMemo(() => {
    const isFirstRowWithPromises = isHeroWithPromises

    if (
      data.template.about.rows.find(({ __typename }) => __typename === ROW_CONTENT) &&
      data.postTypeMeta.isSummaryDisplayed
    ) {
      return (
        <RowSummary
          isColored={data.postTypeMeta.summaryColor === 'dark'}
          isFirstRowWithPromises={isFirstRowWithPromises}
          rows={data.template.about.rows}
        />
      )
    }
  }, [data])

  const rowsComponent = useMemo(() => {
    return data.template.about.rows.map(({ __typename, ...moduleProps }, index) => {
      const isFirstRowWithPromises = isHeroWithPromises && index === 0 && !rowSummary
      const rowProps = { ...moduleProps, isFirstRowWithPromises, key: index }

      switch (__typename) {
        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsArticleslink':
        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsInfo':
        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsLabels':
        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsTabs':
          return <Row as={blocks[__typename]} rowData={{ databaseId: data.databaseId, tagsData }} {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsCards':
          return <RowCards rowData={{ databaseId: data.databaseId, tagsData }} {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsContent':
          return <RowContent rowData={{ databaseId: data.databaseId, tagsData }} {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsDefault':
          return <RowTextPicture rowData={{ databaseId: data.databaseId, tagsData }} {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsTimeline':
          return <RowTimeLine rowData={{ databaseId: data.databaseId, tagsData }} {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsMedia':
          return (
            <RowMedia articlesProps={articlesProps} rowData={{ databaseId: data.databaseId, tagsData }} {...rowProps} />
          )

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsDistribution':
          return <DistributionRow rates={rates.data} {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsCarousel':
          return (
            <RowCarousel
              designSystem={data.template.about.designSystem}
              {...mapObject(rowProps, 'entries.picto', pictoComponents)}
            />
          )

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsHeroN1':
        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsHeroN2':
          return <Row as={blocks[__typename]} {...mapObject(rowProps, 'promises.picto', pictoComponents)} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsHeroRates':
          return <RowRatesContainer rates={rates.data} {...rowProps} />

        case ROW_INPUT:
          return <RowInput {...rowProps} />

        case ROW_SIMULATOR2:
          return <RowSimulator {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsInputBanner':
          return <InputBannerRow {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsLinks':
          return <Row as={blocks[__typename]} {...mapObject(rowProps, 'links.picto', pictoComponents)} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsPictos':
          return <Row as={blocks[__typename]} {...mapObject(rowProps, 'pictos.picto', pictoComponents)} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsProfil':
          return <ProfilRow rates={rates.data} {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsRegions':
          return <RegionsRow rates={rates.data} {...rowProps} />

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsAnalysis':
          return (
            <RatesAnalysisRow
              author={{ ratesSettings, ratesAuthor: data.author.node }}
              rates={rates.data}
              {...rowProps}
            />
          )

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsSimulator': {
          return <SimulatorRowContainer {...rowProps} />
        }

        case 'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsVideo':
          return <VideoRow {...rowProps} />

        default:
          return null
      }
    })
  }, [data, isHeroWithPromises])

  return (
    <BlocsView {...props} menuComponent={menuComponent} rowsComponent={[heroComponent, rowSummary, ...rowsComponent]} />
  )
}

Blocs.propTypes = {
  articlesProps: PropTypes.object,
  data: PropTypes.shape({
    author: PropTypes.object,
    databaseId: PropTypes.string,
    date: PropTypes.string,
    primaryCategory: PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    modified: PropTypes.string,
    postTypeMeta: PropTypes.object,
    template: PropTypes.shape({
      about: PropTypes.shape({
        designSystem: PropTypes.string.isRequired,
        secondaryMenu: PropTypes.object,
        hero: PropTypes.array,
        rows: PropTypes.arrayOf(
          PropTypes.shape({
            __typename: PropTypes.oneOf([
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsArticleslink',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsCards',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsCarousel',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsContent',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsDefault',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsDistribution',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsHeroN1',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsHeroN2',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsHeroRates',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsInfo',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsLabels',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsLinks',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsPictos',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsProfil',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsSimulator',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsTabs',
              'WpTemplate_Blocs_About_AboutContentRows_AboutContentRowsTimeline',
            ]).isRequired,
          })
        ).isRequired,
      }).isRequired,
    }).isRequired,
    title: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  isSecondaryMenuDisplayed: PropTypes.bool,
  menu: PropTypes.shape({
    menuItems: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string.isRequired })),
    }).isRequired,
  }),
  pictoComponents: PropTypes.arrayOf(PropTypes.elementType).isRequired,
  rates: PropTypes.object,
  ratesSettings: PropTypes.object,
  secondaryMenu: PropTypes.object,
  tagsData: PropTypes.object,
}
